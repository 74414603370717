<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  },
  field: {
    type: Object,
    default: () => {}
  }
});

const isTrue = computed(() => !!props.data.item?.[props.field?.key]);
const iconConfig = computed(() => ({
  icon: isTrue.value ? "fa-check" : "fa-xmark",
  class: isTrue.value
    ? "tablehelper-boolean-success"
    : "tablehelper-boolean-error"
}));
</script>

<template>
  <div>
    <i
      class="tablehelper-boolean fal"
      :class="[iconConfig.icon, iconConfig.class]"
    ></i>
  </div>
</template>

<style lang="scss" scoped>
.tablehelper-boolean {
  aspect-ratio: 1;
  border-radius: 4px;
  padding: 6px;

  &-success {
    background-color: rgba($color-connect-success, 0.2);
    color: darken($color-connect-success, 10%);
  }

  &-error {
    background-color: rgba($color-connect-error, 0.2);
    color: $color-connect-error;
  }
}
</style>
